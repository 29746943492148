<template>
  <div class="live_detail">
    <router-view name="live"></router-view>
  </div>
</template>

<script>
  export default {
    name: 'LiveDetail'
  }
</script>

<style scoped>
  .live_detail{
    width: 100%;
    background: #FAFAFA;
  }
</style>
